<template>
    <main>
        <SelectModal
            v-if="selectModal.open"
            :title="selectModal.title()"
            :list="selectModal.data()"
            @select-item="handleSelectModalClick"
            @close="handleModal('close')"
        />

        <header class="header-operator">{{ $t('Operator.Barcode.Header') }}</header>

        <ScannedOrders :listOrders="listScannedOrders" v-if="listScannedOrders.length > 0" />

        <div class="barcode-container" v-else>
            <BarCodeIcon />
            <h4> {{ $t('Operator.Barcode.Title') }}</h4>
            <h6>{{ $t('Operator.Barcode.Subtitle') }}</h6>
        </div>

        <div class="float-bar" v-if="listScannedOrders.length > 0">
            <span class="filtered-orders">
                {{ this.listScannedOrders.length }}
                {{ this.listScannedOrders.length > 1 ? $t('Operator.scannedorders') : $t('Operator.ScannedOrder') }}
            </span>

            <div class="box-btn">
                <b-button class="btn-clean" @click="listScannedOrders = []">
                    {{ $t('Operator.Clean') }}
                </b-button>

                <b-button class="btn-point" @click="redirectOption(1)" v-if="showReworkButton">
                    {{ $t('Operator.Barcode.Rework') }}
                </b-button>
                <b-button class="btn-point" @click="handleModal('open')" v-else>
                    {{ $t('Operator.Point') }} <ArrowDownIcon />
                </b-button>
            </div>
        </div>
    </main>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import { BFormInput, BButton } from 'bootstrap-vue'
import { mapGetters } from "vuex";
import BarCodeIcon from '@/assets/images/icons/barcode-icon.svg'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SelectModal from './components/SelectModal/SelectModal.vue';
import ArrowDownIcon from '@/@core/assets/icons/arrow-down.svg';

export default {
    components: {
        BarCodeIcon,
        BFormInput,
        BButton,
        ToastificationContent,
        SelectModal,
        ArrowDownIcon,
        ScannedOrders: () => import('./ScannedOrders.vue'),
    },
    data() {
        return {
            currentSite: localStorage.getItem('currentSite'),
            prefix: localStorage.getItem('prefix') || this.$router.currentRoute.params.prefix,
            barcode: null,
            listScannedOrders: [],
            timeout: [],
            setTimeout: true,
            localeOpen: false,
            showReworkButton: false,
            cleanBarCodeTimeout: null,
            selectModal: {
                open: false,
                title: () => this.$t('Operator.SelectAppointmentType.Title'),
                data: () => [
                    {
                        id: 0,
                        name: this.$t('Operator.SelectAppointmentType.Items.Productive'),
                        redirect: () => this.redirectOption(0)
                    },
                    {
                        id: 1,
                        name: this.$t('Operator.SelectAppointmentType.Items.Rework'),
                        redirect: () => this.redirectOption(1)
                    },
                ]
            }
        }
    },
    methods: {
        ...mapMutations('app', ['UPDATE_FLAG_SHOW_OVERLAY']),
        ...mapActions('app', [
            'removeRequestLoading'
        ]),
        handleSelectModalClick(value) {
            value.item.redirect();
        },
        handleModal(action) {
            this.selectModal.open = action === 'open';
        },
        redirectOption(option) {
            const redirect = this.listScannedOrders.length > 1
                ? {
                    name: 'operator-production-progress-multiple',
                    params: {
                        prefix: this.prefix,
                        resource_id: this.listScannedOrders[0].resource_id,
                        multiple: 'true',
                        preactor_orders: this.listScannedOrders.map(item => item.preactor_order_id).join(','),
                        orders: this.listScannedOrders,
                        appointment_type: option
                    }
                }
                : {
                    name: 'operator-production-progress',
                    params: {
                        prefix: this.prefix,
                        resource_id: this.listScannedOrders[0].resource_id,
                        return_orders: 'true',
                        multiple: 'false',
                        order_id: this.listScannedOrders[0].preactor_order_id,
                        orders: [],
                        appointment_type: option
                    }
                };

            this.$router.push(redirect);
        },
        readBarcode() {
            if (this.barcode.length) {
                this.getOrders(this.barcode);
                this.$nextTick(() => {
                    this.barcode = '';
                });
            }
        },
        getOrders(orderNumber) {
            this.UPDATE_FLAG_SHOW_OVERLAY(true);

            this.$http.get(`/api/${this.prefix}/operator/orders?site_guid=${this.currentSite}&filter=${orderNumber}`)
                .then(res => {
                    if (res.data.response && res.data.response.length > 0) {
                        this.validateOrder(res.data.response[0]);
                    }
                    else
                        this.showToast(this.$t('RefuseStatus.Error'), 'FrownIcon', this.$t('Operator.ErrorOrderNotFound'), 'danger');
                })
                .catch(() => {})
                .finally(() => this.removeRequestLoading());
        },
        validateOrder(order) {
            if (this.listScannedOrders.length == 0) {
                this.listScannedOrders.push(order);
                this.showReworkButton = order.is_rework == 1;
                return;
            }

            const firstScannedOrder = this.listScannedOrders[0];
            const existOrder = this.listScannedOrders.find(f => f.id == order.id);

            if(existOrder) return;
            if (firstScannedOrder.control_by_hour == 1)
                return this.showToast(this.$t('RefuseStatus.Error'), 'FrownIcon', this.$t('Operator.ErrorOrderByHour'), 'danger');
            if (order.status != firstScannedOrder.status && order.control_by_hour == 0)
                return this.showToast(this.$t('RefuseStatus.Error'), 'FrownIcon', this.$t('Operator.ErrorOrderDiffStatus'), 'danger');
            if (order.control_by_hour == 1)
                return this.showToast(this.$t('RefuseStatus.Error'), 'FrownIcon', this.$t('Operator.ErrorOrderDiffType'), 'danger');
            if (firstScannedOrder.is_rework == 1 && order.is_rework == 0)
                return this.showToast(this.$t('RefuseStatus.Error'), 'FrownIcon', this.$t('Operator.ErrorOrderProductive'), 'danger');
            if (firstScannedOrder.is_rework == 0 && order.is_rework == 1)
                return this.showToast(this.$t('RefuseStatus.Error'), 'FrownIcon', this.$t('Operator.ErrorOrderRework'), 'danger');

            this.listScannedOrders.push(order);
        },
        handleKeydown(event) {
            const { key } = event;

            if(key.length === 1) this.barcode = this.barcode ? this.barcode + key : key;
            event.key === 'Enter' && this.readBarcode();
        },
        clearBarCode() {
            this.barcode = '';
        },
        showToast(title, icon, text, variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: title,
                    icon: icon,
                    text: text,
                    variant,
                },
            })
        },
    },
    mounted() {
        window.addEventListener('keydown', this.handleKeydown)
        this.UPDATE_FLAG_SHOW_OVERLAY(false);
    },
    beforeDestroy() {
        window.removeEventListener('keydown', this.handleKeydown)

        this.$root.$off("bv::dropdown::show");
        this.$root.$off("bv::dropdown::hide");
    },
    computed: {
        ...mapGetters('app', [
            'flagShowModalPin',
        ]),
    },
    watch: {
        flagShowModalPin(value) {
            if(value) {
                window.removeEventListener('keydown', this.handleKeydown);
                this.$root.$off("bv::dropdown::show");
                this.$root.$off("bv::dropdown::hide");

                return;
            }

            window.addEventListener('keydown', this.handleKeydown)
        },
        barcode(v) {
            clearTimeout(this.cleanBarCodeTimeout);

            if(v) this.cleanBarCodeTimeout = setTimeout(() => this.clearBarCode(), 1000)
        }
    }
}
</script>

<style lang="scss" scoped>
.header-operator {
    border-top: 1px solid #CFC4BE;
    margin-top: -48px !important;
    padding: 16px 29px;
    z-index: 1;
}

main {
    width: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}

header {
    width: 100vw;
    position: fixed;
    margin-top: -44px;

    display: flex;
    align-items: center;

    font-family: "Raleway";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;

    background: #ffffff;
    color: #4c4541;
    box-shadow: 0px 8px 30px rgba(58, 46, 38, 0.1);
}

.barcode-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 310px;

    svg {
        margin-bottom: 37px;
        margin-top: -120px;
    }

    h4,
    h6 {
        font-family: "Raleway";
        font-style: normal;
        text-align: center;
    }

    h4 {
        font-weight: 600;
        font-size: 22px;
        line-height: 30px;
        color: #4c4541;
        margin-bottom: 8px !important;
    }

    h6 {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #998f8a;
    }
}

.float-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    gap: 16px;

    width: 563px;
    height: 70px;

    background: #FFFFFF;
    border: 1px solid #CFC4BE;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2);
    border-radius: 6px;

    position: fixed;
    bottom: 71.5px;
    z-index: 11;
    font-size: 12px;
    outline: none;
    right: 27px;

    .filtered-orders {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #4C4541;
        margin-right: auto;
    }
}

.btn-point {
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 23px !important;
    color: #FFFFFF !important;
    border-color: #974900 !important;
    background-color: #974900 !important;
    border-radius: 5px !important;
    height: 38px !important;
    padding: 6px 20px;
    letter-spacing: 0.175px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    svg {
        height: 14px;
        width: 14px;
    }

    &:hover {
        box-shadow: none !important;
    }
}

.btn-clean {
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    letter-spacing: 0.175px;
    color: #974900 !important;
    background-color: #FFFFFF !important;
    border-radius: 5px;
    height: 38px !important;
    padding: 6px 20px;
    border: 1px solid #974900 !important;

    &:hover {
        background-color: #ffede1 !important;
        box-shadow: none !important;
    }
}

.box-btn {
    display: flex;
    gap: 15px;
    justify-content: end;
}

@media screen and (max-width: 768px) {
    header {
        font-size: 36px;
        line-height: 50px;
        padding: 16px 29px;
        margin-top: -50px;
    }

    .float-bar {
        right: auto;
        left: auto;
        width: calc(100% - 2.4rem);
        height: 78px;

        .filtered-orders {
            font-size: 18px;
        }
    }

    .btn-clean {
        height: 46px !important;
    }

    .btn-point {
        height: 46px !important;
        line-height: 33px !important;
    }
}

@media screen and (max-width: 593px) {
    .float-bar {
        flex-direction: column;
        align-items: center;
        height: 120px;
        padding: 16px;

        .filtered-orders {
            margin-right: initial;
            font-size: 18px
        }

        .btn-clean {
            height: 46px !important;
            width: 100% !important;
            font-size: 17px !important;
            padding: 13px 28px;
        }

        .btn-point {
            height: 46px !important;
            width: 100% !important;
            font-size: 17px !important;
            padding: 13px 28px;
            line-height: 20px !important;

            svg {
                width: 16px !important;
                height: 16px !important;
            }
        }

        .box-btn {
            width: 100%
        }
    }
}

@media screen and (max-width: 575px){
    .header-operator {
        padding: 16px !important;
    }
}

@media screen and (max-width: 480px) {
    .header-operator {
        border-top: 1px solid #CFC4BE;
    }
}


@media screen and (max-width: 425px) {
    header {
        font-size: 18px;
        line-height: 26px;
    }

    .barcode-container {
        h4 {
            font-size: 16px;
            line-height: 26px;
        }

        h6 {
            font-size: 12px;
            line-height: 16px;
        }

        svg {
            margin-top: -220px !important;
        }
    }

    .float-bar {
        height: 96px;

        .filtered-orders {
            margin-right: initial;
            font-size: 14px
        }

        .btn-clean {
            height: 28px !important;
            width: 100% !important;
            font-size: 12px !important;
            padding: 2px 14px;
        }

        .btn-point {
            height: 28px !important;
            width: 100% !important;
            font-size: 12px !important;
            padding: 2px 14px;
            line-height: 23px !important;

            svg {
                width: 12px !important;
                height: 12px !important;
            }
        }
    }
}
</style>
